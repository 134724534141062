import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {Observable} from 'rxjs';
import {RestService} from './rest.service';
import {environment} from '../global/app';
import {map} from 'rxjs/operators';
import { IAuthResponse} from '../interfaces/auth-response';
import {StorageService} from './storage.service';
import {Credentials, ICredentials} from '../interfaces/credentials';
import {AccountService} from "./account.service";
import {IAuthReset} from "../interfaces/auth";
import {CouponService} from "./coupon.service";

class AuthServiceController extends RestService {

  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn = this.isLoggedIn$.asObservable();
  private credentials$: BehaviorSubject<ICredentials> = new BehaviorSubject<ICredentials>(
    new Credentials().deserialize({token: null, resource: null}
    ));
  public credentials = this.credentials$.asObservable();

  constructor() {
    super();
    const credentials = new Credentials().deserialize(StorageService.get('credentials'));
    this.setApi(environment.REST_API);
    this.credentials$.next(credentials);
    if (credentials
      && credentials.hasOwnProperty('accessToken')
      && credentials.accessToken !== null) {
      this.isLoggedIn$.next(true);
    } else {
      this.isLoggedIn$.next(false);
    }
  }

  public isLoggedInValue() {
    return this.isLoggedIn$.getValue();
  }
  public resetPassword(identifier: string,url:string): Observable<any> {
    return this.create('/reset-password', {email:identifier,url:url}).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public changePassword(reset: IAuthReset): Observable<any> {
    const data = Object.assign(reset)
    return this.create(`/set-password`, data).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public login(coupon: string): Observable<IAuthResponse> {
    return this.create('/auth/login', {coupon, locale: 'de',type:'coupon'}).pipe(
      map((response: IAuthResponse) => {
        this.setCredentials(response);
        return response;
      })
    );
  }
  public userLogin(user:any): Observable<IAuthResponse> {
    let email = user.email;
    let password = user.password;
    return this.create('/auth/login', {password,email, locale: 'de',type:'login'}).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public userRegister(user:any): Observable<IAuthResponse> {
    let email = user.email;
    let password = user.password;
    return this.create('/auth/register', {password,email, locale: 'de',type:'register'}).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public async refreshToken(type: string, refreshToken: string): Promise<any> {
          const response = await lastValueFrom(
              this.create("/auth/refresh-token", { type, refreshToken })
          );
          this.setCredentials(response);
          return response;
      }
  public isAdmin() {
    return AccountService.hasRole("admin")
  }



  public logout() {
   this.removeCredentials();
   AccountService.remove()
   CouponService.remove()
   StorageService.set('account', null);
   StorageService.set('coupon', null);
  }

  protected override setCredentials(auth: IAuthResponse) {
    super.setCredentials(auth);
    this.isLoggedIn$.next(true);
    AccountService.set(auth?.user);
  }

  public removeCredentials() {
    this.credentials$.next(null);
    StorageService.set('credentials', null);
    this.setAuthHeader(null);
    this.isLoggedIn$.next(false);
  }


}

export const AuthService = new AuthServiceController();
