import {RouterDirection} from '@ionic/core';
import {AuthService} from '../services/auth.service';
import {FixedEncodeURIComponent} from './string-utils';
import {AccountService} from "../services/account.service";
import {StorageService} from "../services/storage.service";
import {i18n} from "../services/i18n.service";
import {ErrorToast} from "./default-toast";
import {ShoppingCartService} from "../services/shopping-cart.service";
import {EnvironmentService} from "../services/environment.service";
import {IError} from "../interfaces/error";

export const GetDomain = () => {
  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
}


export const RouterNavigate = async (path:string, direction: RouterDirection = 'forward') => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.push(path, direction)
  }else{
    location.href=path;
  }
};


export const IsLoggedInGuardWithUserCheck = () => {
  const isLoggedIn = AuthService.isLoggedInValue();
  if (isLoggedIn ) {
    return true;
  } else {
    StorageService.set('budget', true);
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const IsLoggedInGuardAdmin = () => {
  const isLoggedIn = AuthService.isLoggedInValue();
  const isAdmin = AccountService.hasRole("admin")
  if (isLoggedIn && isAdmin) {
    return true;
  } else {
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const IsLoggedInGuard = () => {
  const isLoggedIn = AuthService.isLoggedInValue();
  if (isLoggedIn) {
    return true;
  } else {
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const CanSeeArticles = () => {
  const isLoggedIn = AuthService.isLoggedInValue();
  const environment = EnvironmentService.getEnvironment();
  if (isLoggedIn && environment.privateShop) {
    return true;
  } else if(environment.privateShop === false) {
   return true
  }else{
    return {redirect: '/not-logged-in'};
  }
}

export const HasShoppingCart = () => {
  let hasShoppingCart:number
  ShoppingCartService.shoppingCartCount.subscribe(count => hasShoppingCart = count);
  if (hasShoppingCart) {
    return true;
  } else {
    window.history.back()
  }
}

export const  OpenUrl = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const href = event.currentTarget.querySelector('a').getAttribute("href");
  RouterNavigate(href,'forward').then();
}

export const RouterBack = () => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.back();
  }else{
    history.back();
  }
};

export const RouterCanNavGoBackThenGo = async () => {
  const nav: HTMLIonNavElement = document.querySelector('ion-nav');
  if (nav && await nav.canGoBack()) {
    return nav.pop({skipIfBusy: true});
  }
  return RouterNavigate("/", 'back').then();
};

export const RouterOpenPayment = (event) => {
  event.preventDefault();
  const successUrl = location.href.split('?')[0];
  RouterNavigate('/payment/'+btoa(FixedEncodeURIComponent(successUrl))).then();
};

export const RouterGetUriParam = (name) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has(name)) {
    return urlParams.get(name);
  }else{
    return null;
  }
};

export const GetCategoryId = (path:string) => {
  if (path.includes('article-list')){
    if (isNaN(parseInt(path.split('/article-list/')[1]))){
      return  0
    }
    return parseInt(path.split('/article-list/')[1])

  }
};



export const RouterErrorHandling = async (error: IError) => {
  const environment = EnvironmentService.getEnvironment();

  if (['noTokenProvided', 'notAuthorized', 'refreshTokenExpired', 'authenticationHasFailed', 'authenticationRequired'].includes(error?.systemCode)) {
    error.systemCode = 'notAuthorized';
  }

  const routingError = i18n({
    userGroupNotFound: 'User is not assigned to any user group.',
    userGroupDeletionFailed:"Unfortunately this user group could not be deleted, please try again in a few minutes.",
    couponExpired:'Unfortunately your coupon has expired.',
    addressNotFound: 'No corresponding address could be found.',
    addressNotCreated: 'Address could not be created.',
    couponNotFound: 'Coupon not found. Please try another coupon.',
    couponAlreadyUsed: 'Coupon already used. Please try another coupon.',
    noUserGroupsFound: 'No user groups available.',
    imageSharpProcessingError: 'Unfortunately we do not support this file format.',
    imageCouldNotBeUploaded: 'The image could not be uploaded – please ensure the filename contains no umlauts or special characters. If the issue persists, please contact support.',
    wrongPassword: 'The password you entered is not correct.',
    shopNotFound: 'No shop found with this ID.',
    noContingentsFound: 'No contingents were found.',
    refreshTokenHasExpired: 'Your session has expired. Please log in again.',
    notAuthorized: 'You are not authorized to access this page.',
    couponLoginFailed:"A problem has occurred while logging in. Please try again.",
    loginFailed:"A problem has occurred while logging in. Please try again.",
    connectionTimeout:"An error has occurred. Please try again in a few minutes.",
    budgetAlreadyExists:"The budget already exists.",
    budgetNotCreated:"The budget could not be prepared.",
    categoryNotFound:"A category must be selected",

  }).t('de', {
    userGroupNotFound: 'Sie sind keiner Benutzergruppe zugeordnet.',
    userGroupDeletionFailed:"Leider konnte diese Benutzergruppe nicht gelöscht werden.Bitte versuchen Sie es in wenigen Minuten erneut.",
    couponExpired: 'Leider ist ihr Coupon abgelaufen.',
    addressNotFound: 'Es konnte keine zugehörige Adresse gefunden werden.',
    addressNotCreated: 'Die Adresse konnte nicht erstellt werden.',
    couponNotFound: 'Der Coupon konnte nicht gefunden werden. Bitte versuchen Sie es mit einem anderen Coupon.',
    couponAlreadyUsed: 'Der Coupon wurde bereits verwendet. Bitte versuchen Sie es mit einem anderen Coupon.',
    noUserGroupsFound: 'Keine Benutzergruppen vorhanden.',
    imageSharpProcessingError: 'Dieses Dateiformat unterstützen wir leider nicht.',
    imageCouldNotBeUploaded: 'Das Bild konnte nicht hochgeladen werden – bitte stellen Sie sicher, dass der Dateiname keine Umlaute oder Sonderzeichen enthält. Bei anhaltenden Problemen wenden Sie sich bitte an den Support.',
    wrongPassword: 'Das eingegebene Passwort ist nicht korrekt.',
    shopNotFound: 'Kein Shop mit dieser ID gefunden.',
    noContingentsFound: 'Es wurden keine Kontingente gefunden.',
    refreshTokenHasExpired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    notAuthorized: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.',
    couponLoginFailed:"Anmelden fehlgeschlagen. Bitte versuchen Sie sich erneut.",
    loginFailed:"Anmelden fehlgeschlagen. Bitte versuchen Sie sich erneut.",
    connectionTimeout:"Ein Fehler ist aufgetreten. Bitte versuchen Sie es in wenigen Minuten erneut.",
    budgetAlreadyExists:"Das Budget existiert bereits",
    budgetNotCreated:"Das Budget konnte nicht erstellt werden.",
    categoryNotFound:"Es muss eine Kategorie ausgewählt werden.",


  }).get(error?.systemCode);

  await ErrorToast((routingError) ? routingError : error.message ,5000);

  switch(error?.systemCode){
    case 'validationError':
      if(error.details.includes('mail')){
        await ErrorToast(i18n('Email is required.').t('de', 'E-Mail ist erforderlich.').get(),5000)
      }
      break;
    case "couponAlreadyUsed":
    case "couponLoginFailed":
      AuthService.logout();
      if (!location.pathname.includes("coupon")){
        location.href = '/';
      }
      break;
    case "refreshTokenHasExpired":
    case "noTokenProvided":
    case "notAuthorized":
    case "refreshTokenExpired":
    case "authenticationHasFailed":
    case "authenticationRequired":
      AuthService.logout();
      if(environment.privateShop){
        location.href = '/';
      }
      break;
    default:
      if (!routingError) {
        await ErrorToast(i18n('An error has occurred. Please try again in a few minutes.').t('de', 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es in wenigen Minuten erneut.').get(),5000)

      }
      break;
  }
};
